import { compose, cva, cx, type VariantProps } from "./style.utils";

// -----------------------------------------------------------------------------

export const buttonBaseClassNames = cx(
  // layout
  "inline-flex items-center justify-center",
  // radii
  "rounded-md",
  // border
  "border border-transparent",
  // foreground
  "text-sm font-semibold",
  // animation
  "transition-colors",
);

export const focusOutlineClassNames = cx(
  // focus unset
  "focus:outline-none",
  // focus keyboard only
  "focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-1 focus-visible:outline-ring",
);

// -----------------------------------------------------------------------------

export const BUTTON_VARIANTS_DEFAULTS = {
  variant: "solid",
  palette: "accent",
  size: "md",
  underline: false,
  iconOnly: false,
  pending: false,
  disabled: false,
} as const;

// Do NOT use classNames, only empty strings.
export const BUTTON_VARIANTS_VARIANTS = {
  variant: {
    unset: null,
    solid: "",
    subtle: "",
    outline: "",
    ghost: "",
    link: "",
  },
  palette: {
    unset: null,
    accent: "",
    error: "",
    success: "",
    neutral: "",
    white: "",
  },
  size: {
    unset: null,
    sm: "", // compact
    md: "", // convenient
    lg: "", // cozy
  },
  iconOnly: {
    false: "",
    true: "",
  },
  underline: {
    false: "",
    true: "",
  },
  pending: {
    false: "",
    true: "",
  },
  disabled: {
    false: "",
    true: "",
  },
};

// -----------------------------------------------------------------------------

export type ButtonVariants = VariantProps<typeof buttonVariants>;

export const buttonVariants = cva({
  base: [buttonBaseClassNames, focusOutlineClassNames],
  variants: {
    ...BUTTON_VARIANTS_VARIANTS,
    variant: {
      ...BUTTON_VARIANTS_VARIANTS.variant,
      outline: "border-current",
      link: [
        // layout/foreground reset
        "rounded-sm font-normal",
        // focus outline
        "focus-visible:outline-offset-2",
      ],
    },
    palette: {
      ...BUTTON_VARIANTS_VARIANTS.palette,
      white: "focus-visible:outline-warning-60",
    },
    pending: {
      ...BUTTON_VARIANTS_VARIANTS.pending,
      true: "relative cursor-progress",
    },
    disabled: {
      ...BUTTON_VARIANTS_VARIANTS.disabled,
      true: "cursor-not-allowed",
    },
  },
  compoundVariants: [
    // borders
    {
      size: ["md", "lg"],
      className: "border-1.5",
    },

    // sizing/spacing
    {
      variant: ["solid", "subtle", "outline", "ghost"],
      size: "sm",
      iconOnly: false,
      className: "h-30px gap-5px px-15px",
    },
    {
      variant: ["solid", "subtle", "outline", "ghost"],
      size: "md",
      iconOnly: false,
      className: "h-36px gap-5px px-20px",
    },
    {
      variant: ["solid", "subtle", "outline", "ghost"],
      size: "lg",
      iconOnly: false,
      className: "h-40px gap-5px px-30px",
    },

    {
      variant: ["solid", "subtle", "outline", "ghost"],
      size: "sm",
      iconOnly: true,
      className: "size-30px p-0",
    },
    {
      variant: ["solid", "subtle", "outline", "ghost"],
      size: "md",
      iconOnly: true,
      className: "size-36px p-0",
    },
    {
      variant: ["solid", "subtle", "outline", "ghost"],
      size: "lg",
      iconOnly: true,
      className: "size-40px p-0",
    },

    // icon offset
    {
      pending: false,
      variant: ["solid", "subtle", "outline", "ghost"],
      iconOnly: false,
      className: [
        // icon before
        "[&_svg:first-child]:-ml-5px",
        // icon after
        "[&_svg:last-child]:-mr-5px",
      ],
    },
    {
      pending: true,
      variant: ["solid", "subtle", "outline", "ghost"],
      iconOnly: false,
      className: [
        // icon before
        "[&_:is([data-content=children]):has(svg:first-child)]:-ml-5px",
        // icon after
        "[&_:is([data-content=children]):has(svg:last-child)]:-mr-5px",
      ],
    },

    // default color state
    {
      variant: "solid",
      palette: "accent",
      className: "bg-accent-idle text-white",
    },
    {
      variant: "solid",
      palette: "error",
      className: "bg-error-100 text-white",
    },
    {
      variant: "solid",
      palette: "success",
      className: "bg-success-100 text-white",
    },
    {
      variant: "solid",
      palette: "white",
      className: "bg-white text-accent-idle",
    },
    {
      variant: "subtle",
      palette: "accent",
      className: "bg-accent-idle/10 text-accent-idle",
    },
    {
      variant: "subtle",
      palette: "error",
      className: "bg-error-100/10 text-error-100",
    },
    {
      variant: "subtle",
      palette: "success",
      className: "bg-success-100/10 text-success-100",
    },
    {
      variant: "subtle",
      palette: "white",
      className: "bg-white/10 text-white",
    },
    {
      variant: ["outline", "ghost"],
      palette: "accent",
      className: "bg-transparent text-accent-idle",
    },
    {
      variant: ["outline", "ghost"],
      palette: "error",
      className: "bg-transparent text-error-100",
    },
    {
      variant: ["outline", "ghost"],
      palette: "success",
      className: "bg-transparent text-success-100",
    },
    {
      variant: ["outline", "ghost"],
      palette: "white",
      className: "bg-transparent text-white",
    },
    {
      variant: "link",
      palette: "accent",
      className: "bg-transparent text-link-idle",
    },
    {
      variant: "link",
      palette: "error",
      className: "bg-transparent text-error-100",
    },
    {
      variant: "link",
      palette: "success",
      className: "bg-transparent text-success-100",
    },
    {
      variant: "link",
      palette: "white",
      className: "bg-transparent text-white",
    },

    // focus/hover/active states
    {
      disabled: false,
      variant: "solid",
      palette: "accent",
      className:
        "hover:bg-accent-hover focus:bg-accent-focus active:bg-accent-active",
    },
    {
      disabled: false,
      variant: "solid",
      palette: "error",
      className: "hover:bg-error-120 focus:bg-error-120 active:bg-error-120",
    },
    {
      disabled: false,
      variant: "solid",
      palette: "success",
      className:
        "hover:bg-success-120 focus:bg-success-120 active:bg-success-120",
    },
    {
      disabled: false,
      variant: "solid",
      palette: "white",
      className: "hover:bg-white/90 focus:bg-white/90 active:bg-white/90",
    },
    {
      disabled: false,
      variant: "subtle",
      palette: "accent",
      className:
        "hover:bg-accent-idle/20 focus:bg-accent-idle/20 active:bg-accent-idle/20",
    },
    {
      disabled: false,
      variant: "subtle",
      palette: "error",
      className:
        "hover:bg-error-100/20 focus:bg-error-100/20 active:bg-error-100/20",
    },
    {
      disabled: false,
      variant: "subtle",
      palette: "success",
      className:
        "hover:bg-success-100/20 focus:bg-success-100/20 active:bg-success-100/20",
    },
    {
      disabled: false,
      variant: "subtle",
      palette: "white",
      className: "hover:bg-white/20 focus:bg-white/20 active:bg-white/20",
    },
    {
      disabled: false,
      variant: ["outline", "ghost"],
      palette: "accent",
      className:
        "hover:bg-accent-idle/10 focus:bg-accent-idle/10 active:bg-accent-idle/10",
    },
    {
      disabled: false,
      variant: ["outline", "ghost"],
      palette: "error",
      className:
        "hover:bg-error-100/10 focus:bg-error-100/10 active:bg-error-100/10",
    },
    {
      disabled: false,
      variant: ["outline", "ghost"],
      palette: "success",
      className:
        "hover:bg-success-100/10 focus:bg-success-100/10 active:bg-success-100/10",
    },
    {
      disabled: false,
      variant: ["outline", "ghost"],
      palette: "white",
      className: "hover:bg-white/10 focus:bg-white/10 active:bg-white/10",
    },
    {
      underline: false,
      disabled: false,
      variant: "link",
      iconOnly: false,
      className: [
        "hover:underline focus:underline active:underline",
        "hover:decoration-1 focus:decoration-1 active:decoration-1",
      ],
    },
    {
      underline: true,
      disabled: [false, true],
      variant: "link",
      iconOnly: false,
      className: "underline decoration-1",
    },
    {
      underline: true,
      disabled: false,
      variant: "link",
      iconOnly: false,
      className:
        "hover:underline-offset-2 focus:underline-offset-2 active:underline-offset-2",
    },
    {
      disabled: false,
      variant: "link",
      palette: "accent",
      className:
        "hover:text-link-hover focus:text-link-focus active:text-link-active",
    },
    {
      disabled: false,
      variant: "link",
      palette: "error",
      className: "hover:text-error-80 focus:text-error-80 active:text-error-80",
    },
    {
      disabled: false,
      variant: "link",
      palette: "success",
      className:
        "hover:text-success-80 focus:text-success-80 active:text-success-80",
    },
    {
      disabled: false,
      variant: "link",
      palette: "white",
      className: "hover:text-white/80 focus:text-white/80 active:text-white/80",
    },

    // disabled states
    {
      disabled: true,
      variant: "solid",
      palette: "accent",
      className: "bg-accent-disabled",
    },
    {
      disabled: true,
      variant: "solid",
      palette: "error",
      className: "bg-error-40",
    },
    {
      disabled: true,
      variant: "solid",
      palette: "success",
      className: "bg-success-40",
    },
    {
      disabled: true,
      variant: "solid",
      palette: "white",
      className: "bg-white/20 text-white/30",
    },
    {
      disabled: true,
      variant: "subtle",
      palette: "accent",
      className: "bg-transparent text-accent-disabled",
    },
    {
      disabled: true,
      variant: "subtle",
      palette: "error",
      className: "bg-transparent text-error-40",
    },
    {
      disabled: true,
      variant: "subtle",
      palette: "success",
      className: "bg-transparent text-success-40",
    },
    {
      disabled: true,
      variant: "subtle",
      palette: "white",
      className: "bg-transparent text-white/30",
    },
    {
      disabled: true,
      variant: ["outline", "ghost"],
      palette: "accent",
      className: "bg-transparent text-accent-disabled",
    },
    {
      disabled: true,
      variant: ["outline", "ghost"],
      palette: "error",
      className: "bg-transparent text-error-40",
    },
    {
      disabled: true,
      variant: ["outline", "ghost"],
      palette: "success",
      className: "bg-transparent text-success-40",
    },
    {
      disabled: true,
      variant: ["outline", "ghost"],
      palette: "white",
      className: "bg-transparent text-white/30",
    },
    {
      disabled: true,
      variant: "link",
      palette: "accent",
      className: "text-link-disabled",
    },
    {
      disabled: true,
      variant: "link",
      palette: "error",
      className: "text-error-40",
    },
    {
      disabled: true,
      variant: "link",
      palette: "success",
      className: "text-success-40",
    },
    {
      disabled: true,
      variant: "link",
      palette: "white",
      className: "text-white/30",
    },

    // "tertiary" default color state
    {
      variant: "outline",
      palette: "neutral",
      className: "border-shade-15 bg-white text-shade-100",
    },
    // "tertiary" focus/hover/active states
    {
      disabled: false,
      variant: "outline",
      palette: "neutral",
      className: [
        "hover:border-shade-20 focus:border-shade-20 active:border-shade-20",
        "hover:bg-shade-8 focus:bg-shade-8 active:bg-shade-8",
      ],
    },
    // "tertiary" disabled states
    {
      disabled: true,
      variant: "outline",
      palette: "neutral",
      className: "border-shade-10 bg-shade-2 text-shade-50",
    },
  ],
  defaultVariants: BUTTON_VARIANTS_DEFAULTS,
});

// -----------------------------------------------------------------------------

export type ButtonContentVariants = VariantProps<typeof buttonContentVariants>;

export const buttonContentVariants = cva({
  base: "inline-flex items-center justify-center",
  variants: {
    iconOnly: {
      ...BUTTON_VARIANTS_VARIANTS.iconOnly,
      false: "gap-5px",
    },
  },
});

// -----------------------------------------------------------------------------

export type ButtonSpinnerVariants = VariantProps<typeof buttonSpinnerVariants>;

export const buttonSpinnerVariants = cva({
  base: [
    // layout
    "flex items-center justify-center",
    // position
    "absolute inset-0",
  ],
});

// -----------------------------------------------------------------------------

export type ButtonWithIndicatorVariants = VariantProps<
  typeof buttonWithIndicatorVariants
>;

export const buttonWithIndicatorVariants = compose(
  buttonVariants,
  cva({
    variants: {
      activeIndicator: {
        true: "relative",
        false: "",
      },
    },
    defaultVariants: {
      activeIndicator: false,
    },
  }),
);

export type ButtonIndicatorVariants = VariantProps<
  typeof buttonIndicatorVariants
>;

export const buttonIndicatorVariants = cva({
  variants: {
    active: {
      true: "absolute right-4px top-4px size-4px rounded-full bg-secondary-100",
      false: "",
    },
  },
  defaultVariants: {
    active: false,
  },
});

// -----------------------------------------------------------------------------

export type ButtonCloseVariants = VariantProps<typeof buttonCloseVariants>;

export const buttonCloseVariants = cva({
  base: [
    buttonBaseClassNames,
    focusOutlineClassNames,
    // layout
    "size-30px",
    // radii/shadows
    "rounded-full",
    // background
    "bg-shade-8",
    // foreground
    "text-shade-40",
    // focus/hover/active
    "hover:bg-shade-10 hover:text-shade-60",
  ],
});

// -----------------------------------------------------------------------------

export type ButtonCloseResponsiveVariants = VariantProps<
  typeof buttonCloseResponsiveVariants
>;

export const buttonCloseResponsiveVariants = cva({
  base: [
    buttonBaseClassNames,
    focusOutlineClassNames,
    // Similar to: size=sm variant=subtle palette=n/a iconOnly
    ...[
      // layout
      "max-sm:size-30px",
      // radii/shadows
      "max-sm:rounded-full",
      // background
      "max-sm:bg-shade-8",
      // foreground
      "max-sm:text-shade-40",
      // focus/hover/active
      "max-sm:hover:bg-shade-10 max-sm:hover:text-shade-60",
    ],
    // Similar to: size=md variant=outline palette=neutral (tertiary)
    ...[
      // layout
      "sm:h-36px sm:gap-5px sm:px-20px",
      // border
      "sm:border-1.5 sm:border-shade-15",
      // background
      "sm:bg-white",
      // foreground
      "sm:text-shade-100",
      // focus/hover/active border
      "sm:hover:border-shade-20 sm:focus:border-shade-20 sm:active:border-shade-20",
      // focus/hover/active background
      "sm:hover:bg-shade-8 sm:focus:bg-shade-8 sm:active:bg-shade-8",
    ],
  ],
});
